import { CSSProperties, useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { useCssStateManager } from "./use_css_state_manager";
import { useResizeObserver } from "./use_resize_observer";
import { useSmartState } from "./use_smart_state";

export type StatusMessageType = "error" | "info";

type StatusMessageProps = {
    color: string;
    message: string;
    show: boolean;
};

export type StatusMessage = {
    type: "info" | "error";
    message: string;
    permanent?: boolean;
};

function StatusMessage({color,message,show}:StatusMessageProps)
{
    const cssStateManager = useCssStateManager(["status-message"]);
    const showRef = useRef(show);
    showRef.current = show;

    const { scalePixelToRem } = useContext(LayoutStateContext);

    const height = useSmartState(0);
    const innerRef = useResizeObserver(() => {
        if (showRef.current) {
            syncHeightToInner();
        }
    });

    const syncHeightToInner = () => {
        if (innerRef.current) {
            const { current: innerElem } = innerRef;
            height.value = scalePixelToRem(innerElem.offsetHeight);            
        }
    };

    useEffect(() => {
        if (show) {
            syncHeightToInner();
        }
        else {
            height.value = 0;
        }
    },[show])

    const styles: CSSProperties = {
        height: `${height.value}rem`,
        color
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()} style={styles}>
                <div ref={innerRef} className="inner">
                    {message}
                </div>                
            </div>
            <style jsx>{`
            .status-message {
                overflow: hidden;
                transition: height 0.4s;
                font-weight: 600;
            }

            .inner {
                width: 100%;
                text-align: center;
                padding-bottom: 25rem;
            }

            `}</style>
        </>
    );
}

let loadedStatusMessage: StatusMessage;

export const useStatusMessage = (headless = false) => {
    const color  = useSmartState("");
    const message = useSmartState("This is the test message");
    const show  = useSmartState(false);
    const closeAfterTimeoutHandle = useRef(0);    

    const defaultColorTable = {
        "error": "#dc0303",
        "info": "#7c79e4"
    };    

    const component = <StatusMessage message={message.value} show={show.value} color={color.value} />;

    useEffect(() => {
        if ((loadedStatusMessage) && (!headless)) {
            const copy = loadedStatusMessage;
            loadedStatusMessage = null;
            ret.showFromObject(copy);
        }
    },[]);

    const ret = {
        component,
        loadMessage(message: StatusMessage){
            loadedStatusMessage = message;
        },
        show(messageValue: string,{closeAfterMs = -1,colorValue = defaultColorTable["info"]}: {closeAfterMs?: number;colorValue?: string} = {}){
            clearTimeout(closeAfterTimeoutHandle.current);
            closeAfterTimeoutHandle.current = 0;
            color.value = colorValue;
            message.value = messageValue;
            show.value = true;
            if (closeAfterMs>0) {
                closeAfterTimeoutHandle.current = setTimeout(() => {
                    ret.close();
                },closeAfterMs) as unknown as number;
            }
        },
        showFromObject({type,message,permanent}: StatusMessage) {
            if (type==="info") {
                ret.showInfo(message,!!permanent);
            }
            else {
                ret.showError(message,(permanent===undefined) || permanent);
            }
        },
        showError(messageValue: string,permanent = true) {
            ret.show(messageValue,{
                colorValue: defaultColorTable["error"],
                ...(permanent?{}:{closeAfterMs: 7000})
            });
        },
        showInfo(messageValue: string,permanent = false) {
            ret.show(messageValue,{
                colorValue: defaultColorTable["info"],
                ...(permanent?{}:{closeAfterMs: 7000})
            });
        },
        close() {
            show.value = false;
        }
    };

    return ret;
};