import { Account } from "../hooks/use_account";
import serverRefs from "../server-refs.json";
import { misc } from "./misc";

export const SECURE_REFRESH_AFTER_MINUTES = 30;
// export const SECURE_REFRESH_AFTER_MINUTES = -1;

export const secureRefreshState = {
    lastRefreshTimestamp: 0,
};

export class RequestErrorPermissionDenied {}
export class RequestErrorNoNetworkConnection {}
export class RequestErrorCustom {
    constructor(public message: string,public inputNames: string[] = []) {}
}

export const postRequest = async (accountValue: Account,relativeUrl: string,input: {[key: string]: any}) => {
    const baseUrl = (process.env.NODE_ENV==="development")?"http://localhost:9000":serverRefs.apis.api;
    let url = `${baseUrl}${relativeUrl}`;
    let renewHash: string;    

    while(true) {
        const res = await (async () => {
            const maxRetries = 5;
            let retries = 0;
            while(true) {
                try {
                    return await fetch(url,{
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            ...input,
                            ...(accountValue?{
                                session: {
                                    userId: accountValue.user.accountNumber,
                                    sessionId: accountValue.session.id,
                                    ...(renewHash?{renewHash}:{})
                                }
                            }:{}),                            
                        })
                    });
                }
                catch(err) {
                    if (retries<maxRetries) {
                        retries++;
                        await misc.sleep(500);
                    }
                    else {
                        throw new RequestErrorNoNetworkConnection();
                    }
                }    
            }
        })();
        const jsonRes = await res.json();
        if (jsonRes?.type==="error") {
            if ((accountValue) && (jsonRes.errorMessage==="session-expired")) {
                renewHash = await misc.sha1Hash(`${accountValue.session.id}-${accountValue.user.accountNumber}-${accountValue.session.renewKey}`);
            }
            else {
                if (jsonRes?.errorMessage==="permission-denied") {
                    throw new RequestErrorPermissionDenied();
                }
                else {
                    throw new RequestErrorCustom(jsonRes?.errorMessage || "Unexpected response from server",Array.isArray(jsonRes?.errorInputNames)?jsonRes?.errorInputNames:[]);
                }                
            }            
        }
        else if (jsonRes?.type==="success") {
            delete jsonRes.type;            
            let newAccountValue: Account;
            if (accountValue) {
                const { refreshUser } = jsonRes;
                if ((renewHash) && (jsonRes.renewedSession)) {
                    const { renewedSession } = jsonRes;
                    newAccountValue = {
                        user: refreshUser,
                        session: renewedSession
                    };
                    delete jsonRes.renewedSession;                                        
                }
                else {
                    newAccountValue = {
                        user: refreshUser,
                        session: accountValue.session
                    };
                }
                secureRefreshState.lastRefreshTimestamp = (new Date()).getTime();
                delete jsonRes.refreshUser;
            }                                    
            return {
                result: jsonRes,
                ...(newAccountValue?{newAccountValue}:{})
            };
        }
        else {
            throw new RequestErrorCustom("Unexpected response from server");
        }    
    }
};
