import { ReactElement, createContext, useContext } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { SmartState, useSmartState } from "../../hooks/use_smart_state";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { Account, consumeAccount, useAccount } from "../../hooks/use_account";
import CenteredSpinner from "./centered-spinner";
import { misc } from "../../lib/misc";
import { InlineButton } from "./button";
import { RequestErrorCustom, RequestErrorNoNetworkConnection, RequestErrorPermissionDenied, SECURE_REFRESH_AFTER_MINUTES, postRequest, secureRefreshState } from "../../lib/server_requests";
import { useRouter } from "next/router";
import { StatusMessage, useStatusMessage } from "../../hooks/use_status_message";

export const SecurePageContext = createContext<boolean>(false);

export const useIsSecurePage = () => {
    return useContext(SecurePageContext);
};

export type RedirectDirective = {
    path: string;
    message?: StatusMessage; 
};

export function securePage<T>(
        Component: (props: {result: T; account: SmartState<Account | null>}) => ReactElement,
        originalRelativeUrl?: string,
        { onBeforeLoad = () => {} }: {onBeforeLoad?: (result: T, account: SmartState<Account | null>,query: {[name: string]: string}) => RedirectDirective | void} = {}
    ) {
    return function() {
        const cssStateManager = useCssStateManager(["secure-page"]);
        const result = useSmartState<T | null>(null);
        const connectionError = useSmartState(false);
        const reactRouter = useRouter();                
        const unexpectedError = useSmartState("");
        
        const router = useCustomRouter();
        const statusMessage = useStatusMessage(true);
        const account = consumeAccount((accountValue) => {
            attempt(accountValue);
        });    

        cssStateManager.useProperty((result.value===null) || !account.value,"not-loaded");

        const attempt = async (accountValue: Account) => {   
            if (accountValue===null) {
                router.push("/login");
            }
            else {
                if ((!accountValue.user.firstName) && (!accountValue.user.lastName)) {
                    const redirectToPath = "/account/first-login/profile";
                    if (!reactRouter.asPath.startsWith(redirectToPath)) {
                        router.push(redirectToPath);
                    }   
                    else {
                        result.value = {} as T;
                    }                 
                }
                else if ((accountValue.user.addresses.length===0) && (!accountValue.user.addressSetupSkipped)) {
                    const redirectToPath = "/account/first-login/address";
                    if (!reactRouter.asPath.startsWith(redirectToPath)) {
                        router.push(redirectToPath);
                    }   
                    else {
                        result.value = {} as T;
                    }                 
                }
                else {
                    const finalize = (value: T) => {        
                        const queryParams = misc.getQueryParams();                
                        const redirectDirective = onBeforeLoad(value,account as SmartState<Account>,queryParams as {[name: string]: string});
                        if (redirectDirective) {
                            if (redirectDirective.message) statusMessage.loadMessage(redirectDirective.message);
                            router.push(redirectDirective.path);
                        }
                        else {
                            result.value = value;
                        }
                    };

                    let relativeUrl = originalRelativeUrl;
                    const input = misc.getQueryParams();
                    try {                                        
                        if ((!relativeUrl) && (SECURE_REFRESH_AFTER_MINUTES>-1)) {
                            const timespan = (new Date()).getTime()-secureRefreshState.lastRefreshTimestamp;
                            if (timespan>=SECURE_REFRESH_AFTER_MINUTES*60*1000) {                            
                                relativeUrl = "/users/account?action=refresh";
                            }
                        }
                        if (relativeUrl) {
                            const { result: _result,newAccountValue } = await postRequest(accountValue,relativeUrl,input);
    
                            if (newAccountValue) {
                                account.value = newAccountValue;
                            }
                            finalize(_result);
                        }
                        else {
                            finalize({} as T);
                        }
                    }
                    catch(err: unknown) {
                        if (err instanceof RequestErrorPermissionDenied) {
                            account.value = null;
                            router.push("/login");
                        }
                        else if (err instanceof RequestErrorNoNetworkConnection) {
                            connectionError.value = true;
                        }
                        else if (err instanceof RequestErrorCustom) {
                            unexpectedError.value = err.message;
                        }
                    }                        
                }
            }
        };

        const handlerRetryClick = () => {
            connectionError.value = false;
            attempt(account.value as Account);
        };

        return (
            <>
                <div className={cssStateManager.getClassNames()}>
                    {(result.value && account.value)?(<>
                        <SecurePageContext.Provider value={true}>
                            <Component result={result.value} account={account as SmartState<Account | null>} />
                        </SecurePageContext.Provider>
                    </>):(
                        <>
                            {(unexpectedError.value!=="")?(
                                <>
                                    <div className="error">
                                        error: {unexpectedError.value}
                                    </div>                                    
                                </>
                            ):(connectionError.value?(
                                <>
                                    <div className="error">
                                        Network error: make sure you are connected to the internet.
                                        <div className="button">
                                            <InlineButton caption="Retry" onClick={handlerRetryClick} />
                                        </div>
                                    </div>
                                </>
                            ):<CenteredSpinner />)}
                        </>
                    )}
                </div>
                <style jsx>{`
                .secure-page {                    
                    color: #333;
                    padding: 50rem 40rem;
                    padding-top: 20rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .not-loaded {
                    /* min-height: 600rem; */
                }

                .error {
                    color: #d20505;
                    text-align: center;
                }

                .button {
                    margin-top: 20rem;
                }
                `}</style>
            </>
        );
    };
};